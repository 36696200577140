function toggleIcon(e) {
    $(e.target).prev().find(".faq-icon").toggleClass('fa-minus fa-plus');
    $(e.target).closest('.panel').toggleClass('open');
}
$('.panel-group').on('hidden.bs.collapse', toggleIcon);
$('.panel-group').on('shown.bs.collapse', toggleIcon);


$(function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $('.popover-dismiss').popover({
    trigger: 'focus'
  })
})

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox();
});


$(function() {
    $("#csr").hide().bind('click', function() { $(this).selectText();} );
    $("#csrform").submit(function(event) {
        event.preventDefault();
        $("#postgenerate").slideDown("slow");
        $.post("inc/lib/csr.php", $(this).serialize(), function(data) {
            var data = JSON.parse(data);
            // console.log(data['key']);
            $("#csr").empty();
            $("#csr").append(data['csr']).fadeIn("slow");
            $("#key").empty();
            $("#key").append(data['key']).fadeIn("slow");
            $('html, body').animate({
                scrollTop: $("#postgenerate").offset().top
            }, 1500);
        }, "text");
   });
});


(function ( $ ) {
    $.fn.stickyTabs = function() {
        context = this

        // Show the tab corresponding with the hash in the URL, or the first tab.
        var showTabFromHash = function() {
          var hash = window.location.hash;
          var selector = hash ? 'a[href="' + hash + '"]' : 'a.active';
          $(selector, context).tab('show');
        }

        // Set the correct tab when the page loads
        showTabFromHash(context)

        // Set the correct tab when a user uses their back/forward button
        window.addEventListener('hashchange', showTabFromHash, false);

        // Change the URL when tabs are clicked
        $('a', context).on('click', function(e) {
          history.pushState(null, null, this.href);
        });

        return this;
    };
}( jQuery ));

$('#ssl-tabs').stickyTabs();


// Select all links with hashes
$('a.scrollto[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });



function validateDomain(the_domain){
  // strip off "http://" and/or "www."
  the_domain = the_domain.replace("http://","");
  the_domain = the_domain.replace("www.","");

  var reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
  return reg.test(the_domain);
}

function validateIpAddress(ipaddress) {
 if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
  {
    return (true)
  }
return (false)
}

$('form.validate-ip button').on('click', function(event){
    var ip = $("input[name='ip']").val();
    if(!validateDomain(ip) && !validateIpAddress(ip) ){
       $('#help-block').show();
       event.preventDefault();
    }
});