$(function () {
  navbar.init();

  pricing_charts.init();
});


var navbar = {
  init: function () {

    // prevent dropdown link click to hide dropdown
    $('.navbar-nav .dropdown-item').click(function (e) {
      e.stopPropagation()
    })

    // toggle for dropdown submenus
    $('.dropdown-submenu .dropdown-toggle').click(function (e) {
      e.preventDefault()
      $(this).parent().toggleClass('show')
      $(this).siblings('.dropdown-menu').toggleClass('show')
    })

    this.fixedBottom()
  },

  dropdownHover: function () {
    var $dropdowns = $('.navbar-nav .dropdown')
    $dropdowns.each(function (index, item) {
      var $item = $(this)

      $item.hover(function () {
        $item.addClass('show')
      }, function () {
        $item.removeClass('show')
      })
    })
  },

  transparentFixed: function () {
    var $navbar = $('.navbar')

    if ($navbar.hasClass('bg-transparent') && $navbar.hasClass('fixed-top')) {
      var navbarTop = $navbar.offset().top + 1

      var scrollingFn = function () {
        var offsetTop = window.scrollY || window.pageYOffset

        if (offsetTop >= navbarTop && $navbar.hasClass('bg-transparent')) {
          $navbar.removeClass('bg-transparent')
        } else if (offsetTop < navbarTop && !$navbar.hasClass('bg-transparent')) {
          $navbar.addClass('bg-transparent')
        }
      }

      $(window).scroll(scrollingFn)
    }
  },

  fixedBottom: function () {
    $navbar = $('.navbar')

    if ($navbar.hasClass('navbar-fixed-bottom')) {
      var navbarTop = $navbar.offset().top + 1

      var scrollingFn = function () {
        var offsetTop = window.scrollY || window.pageYOffset

        if (offsetTop >= navbarTop && !$navbar.hasClass('navbar-fixed-bottom--stick')) {
          $navbar.addClass('navbar-fixed-bottom--stick')
        } else if (offsetTop < navbarTop && $navbar.hasClass('navbar-fixed-bottom--stick')) {
          $navbar.removeClass('navbar-fixed-bottom--stick')
        }
      }
    }

    $(window).scroll(scrollingFn)
  }
};


var pricing_charts = {
  init: function () {
    var tabs = $(".pricing-charts-tabs .tab"),
        prices = $(".pricing-charts .chart .price"),
        links =  $(".pricing-charts .action a");

    tabs.click(function () {
      tabs.removeClass("active");
      $(this).addClass("active");

      var period = $(this).data("tab");

      var price_out = prices.filter(":not(."+ period +")");
      price_out.addClass("go-out");
      prices.filter("." + period + "").addClass("active");

      var link_out = links.filter(":not(."+ period +")");
      link_out.addClass("go-out");
      links.filter("." + period + "").addClass("active");

      price_out.removeClass("go-out").removeClass("active");
      link_out.removeClass("go-out").removeClass("active");

    });
  }
};
